<template>
  <div
    class="d-flex flex-column align-center pb-15 white--text w-100 h-100 px-5"
    style="background-color: #084468;"
  >
    <div class="mb-6 d-flex align-center mt-15" style="margin-top: 30px">
      <v-img
        :src="require('@/assets/logo_without_text.svg')"
        width="65"
        height="65"
        contain
      />
      <v-img
        :src="require('@/assets/svg/integration.svg')"
        width="30"
        height="30"
        contain
        class="mx-5"
      />
      <v-img
        :src="require('@/assets/svg/instagram.svg')"
        width="65"
        height="65"
        contain
      />
    </div>
    <v-progress-linear
      color="white accent-4"
      indeterminate
      rounded
      height="3"
      v-if="loading"
    />
    <div class="privacy_title" v-if="$route.query.code && !error">
      Authentication successful!<br /><br />
      <div class="font-18">
        Hooray! Your Instagram account is now linked. You can seamlessly post
        media to your Twitter feed right from here. Start tweeting your favorite
        moments and let your followers be a part of your journey. We're excited
        to see what you'll share!<br /><br />
        This dialog will closed shortly...
      </div>
    </div>
    <span class="privacy_title mt-6" v-else>Authentication failed</span>
    <span class="font-18 mt-6" v-if="$route.query.error">{{
      route.query.error
    }}</span>
    <span class="font-18 mt-6" v-if="error">{{ error }}</span>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      channel: new BroadcastChannel("social_connected_callback"),
      loading: false,
      error: null
    };
  },
  methods: {
    ...mapActions({
      connectInstagram: "auth/connectInstagram"
    })
  },
  mounted() {
    console.log(this.$route.query.code);
    if (this.$route.query.code) {
      this.loading = true;
      this.connectInstagram({ code: this.$route.query.code })
        .then(() => {
          this.loading = false;
          this.channel.postMessage("Instagram connected successful!");
          setTimeout(() => {
            window.close();
          }, 2000);
        })
        .catch(error => {
          this.loading = false;
          if (error.response.data.res.error.message) {
            this.error = error.response.data.res.error.message;
          } else if (error.response.data.message) {
            this.error = error.response.data.message;
          } else this.error = error;
          setTimeout(() => {
            window.close();
          }, 10000);
        });
    } else {
      setTimeout(() => {
        window.close();
      }, 10000);
    }
  }
};
</script>
<style scoped>
.privacy_title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
</style>
