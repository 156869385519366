<template>
  <div class="d-flex flex-column align-center white--text">
    <v-row class="w-100">
      <v-col cols="12" md="5" class="px-0">
        <v-img
          src="@/assets/about_image1.png"
          lazy-src="@/assets/about_image1_small.jpg"
          width="100%"
        />
      </v-col>
      <v-col cols="12" md="7" class="px-0">
        <div
          style="background-color: #052E45; color: #FDF1E8; width: 100%; padding-top: 20px; height: 100%; padding-bottom: 20px; padding-left: 60px; padding-right: 60px;"
          class="d-flex flex-column justify-center"
        >
          <span class="app-bold-font" style="font-size: 50px;">
            Join our aKinder™Volunteer<br />
            platform!
          </span>
          <span class="font-20 mt-5">
            Create a significant and positive impact in your community.
          </span>
          <div class="mt-5">
            <v-btn @click="goHome">Starts now</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <div
      class="w-100 d-flex flex-column align-center bg-white"
      style="padding-top: 100px;"
    >
      <span
        class="font-40 app-bold-font dark-blue-color px-5 text-center"
        style="max-width: 860px;"
        >Connect with people passionate about<br />
        the same cause. We explain how:</span
      >
      <span
        class="font-22 primary-color app-regular-font px-5 text-center my-8"
        style="max-width: 860px;"
        >You will learn the key features that make our platform a powerful tool
        to create a kinder world. Get ready to be inspired and make a
        difference!</span
      >
      <div style="max-width: 1200px; max-height: 675px; margin-bottom: 32px;">
        <vue-plyr
          ref="plyr"
          :options="{
            autoplay: false,
            autopause: true,
            controls: [
              'play',
              'progress',
              'current-time',
              'mute',
              'volume',
              'fullscreen'
            ]
          }"
        >
          <video controls crossorigin playsinline id="videoElement">
            <source :src="tutorialVideo" type="video/mp4" />
          </video>
        </vue-plyr>
      </div>
      <v-btn
        @click="goHome"
        color="#052E45"
        dark
        style="margin-bottom: 100px;"
        class="rounded-lg"
      >
        Start now
      </v-btn>
    </div>
    <div
      class="w-100 d-flex flex-column align-center bg-primary"
      style="padding-top: 100px;"
    >
      <span
        class="font-40 app-bold-font px-5"
        style="max-width: 860px; color: #E7EFF4;"
      >
        Benefits and positive experience
      </span>
      <span
        class="font-22 app-regular-font px-5 text-center my-8"
        style="max-width: 860px; color: white;"
      >
        aKinder™Volunteer is your passport to a world of meaningful volunteer
        opportunities. By joining our platform, you will enjoy a number of key
        benefits that will allow you to make a real difference in your
        community:
      </span>
      <div style="margin-bottom: 32px; margin-bottom: 100px;">
        <div class="d-flex flex-row">
          <div
            class="d-flex flex-column px-10 flex-grow align-center justify-center"
          >
            <div class="d-flex flex-column" style="max-width: 740px;">
              <span class="font-28 white--text">Connect without Borders</span>
              <span class="font-22 dark-white-color mt-8"
                >Our platform provides a global community of volunteers and
                charities, removing geographical barriers and connecting people
                with causes they are passionate about.</span
              >
              <div>
                <v-btn @click="goUserRegister" class="rounded-lg mt-8">
                  Go to platform
                </v-btn>
              </div>
            </div>
          </div>
          <div style="max-width: 45%;">
            <v-img
              width="840"
              height="408"
              src="@/assets/bg_about_experience1.png"
            />
          </div>
        </div>
        <div class="d-flex flex-row">
          <div style="max-width: 45%;">
            <v-img
              width="840"
              height="408"
              src="@/assets/bg_about_experience2.png"
            />
          </div>
          <div
            class="d-flex flex-column px-10 flex-grow align-center justify-center"
          >
            <div class="d-flex flex-column" style="max-width: 740px;">
              <span class="font-28 white--text">Efficient management</span>
              <span class="font-22 dark-white-color mt-8"
                >We offer volunteer management tools for agencies and
                organizations, simplifying the coordination and tracking of
                volunteer work.</span
              >
              <div>
                <v-btn @click="goAgencyRegister" class="rounded-lg mt-8">
                  Go to platform
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div
            class="d-flex flex-column px-10 flex-grow align-center justify-center"
          >
            <div class="d-flex flex-column" style="max-width: 740px;">
              <span class="font-28 white--text">Measurable Impact</span>
              <span class="font-22 dark-white-color mt-8"
                >We help quantify the impact of volunteering, allowing
                organizations and volunteers to see the real value of their
                contribution.</span
              >
              <div>
                <v-btn @click="goUserRegister" class="rounded-lg mt-8">
                  Go to platform
                </v-btn>
              </div>
            </div>
          </div>
          <div style="max-width: 45%;">
            <v-img
              width="840"
              height="408"
              src="@/assets/bg_about_experience3.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div style="padding-top: 100px;padding-bottom: 80px;" class="bg-image">
      <div class="d-flex flex-column align-center">
        <span class="font-40 white--text">Do you prefer to explore first?</span>
        <span class="font-22" style="color: #E7EFF4;"
          >Try our platform for free to see all the features and possibilities
          we have to offer.
        </span>
        <v-btn @click="goUserRegister" class="rounded-lg mt-8">
          Go to platform
        </v-btn>
      </div>
    </div>
    <div
      style="background-color: #EAF6F5;padding-top: 100px;"
      class="d-flex flex-column w-100 align-center"
    >
      <span
        class="font-40 app-bold-font dark-blue-color px-5"
        style="max-width: 860px;"
        >FAQ's</span
      >
      <span
        class="font-18 primary-color app-regular-font px-5 text-center my-8"
        style="max-width: 1000px;"
        >aKinder™Volunteer is designed to connect volunteers with meaningful
        opportunities to make a difference in their communities. Our platform
        allows agencies to post volunteer needs, and volunteers can easily apply
        and track their volunteer hours. We believe that everyone has the power
        to create positive change, and we're here to facilitate that process.
        <br /><br />
        With aKinder™Volunteer, you can explore a wide range of volunteer
        opportunities across various causes and interests. Whether you're
        passionate about arts and culture, education, health, or any other
        cause, we have something for you.
        <br /><br />
        Our user-friendly interface provides easy navigation, allowing you to
        browse through event feeds, volunteer needs, chat with other members,
        and stay updated with notifications. You can also create a profile,
        connect with like-minded individuals, and even post blogs to share your
        experiences.
        <br /><br
      /></span>

      <div class="mx-6" style="max-width: 1400px; margin-bottom: 100px;">
        <v-row>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                1. How do I sign up as a volunteer?
              </v-card-title>
              <v-card-text>
                You can sign up as a volunteer by clicking on the “Register as
                Volunteer” button on our login page. Fill in the required
                information, and you'll be ready to start exploring volunteer
                opportunities right away!
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                2. How can my agency post volunteer opportunities?
              </v-card-title>
              <v-card-text>
                If you're representing an agency, you can post volunteer
                opportunities by first creating an agency account. Once your
                account is set up, you can navigate to the "Volunteer
                Opportunities" section click "Create" button, and fill in the
                details of the volunteer opportunity.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                3. Is there a fee for using aKinder™Volunteer?
              </v-card-title>
              <v-card-text>
                If you're representing an agency, you can post volunteer
                opportunities by first creating an agency account. Once your
                account is set up, you can navigate to the "Volunteer
                Opportunities" section click "Create" button, and fill in the
                details of the volunteer opportunity.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                4. Can I track my volunteer hours on the platform?
              </v-card-title>
              <v-card-text>
                Yes, once you have signed up as a volunteer and participated in
                an opportunity, you can log and track your volunteer hours
                directly by auto time recorder or manual time recorder.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                5. How can I connect with other volunteers?
              </v-card-title>
              <v-card-text>
                You can connect with other volunteers by using our search
                feature to browse through user profiles and utilizing our
                messaging feature to reach out and communicate directly.
                Additionally, the platform highlights volunteers with matching
                interests and causes in the “Change Makers” section, making it
                easier for you to connect with like-minded individuals.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                6. Is my personal information safe on the platform?
              </v-card-title>
              <v-card-text>
                We take user privacy and data security very seriously. Your
                personal information is securely stored and will not be shared
                with third parties without your consent. For more information,
                please refer to our Privacy Policy.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                7. How do I report a problem or get assistance?
              </v-card-title>
              <v-card-text>
                If you encounter any issues or require assistance, you have
                several options to reach out for help. You can contact our
                support team directly via email at dblais@danielstable.org, or
                you can use the chat feature available on our platform for
                immediate assistance. Additionally, we offer an AI chat feature
                that can provide basic guidance and support for using our
                platform. Our team is here to help and ensure you have a smooth
                experience with aKinder™Volunteer.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                8. Can I edit or delete my account?
              </v-card-title>
              <v-card-text>
                Absolutely, you have full control over your account on
                aKinder™Volunteer. To edit your account information, simply
                navigate to your profile page where you can update your personal
                details. If you decide that you would like to delete your
                account, you can do so by going to Settings -> Account. In this
                section, you will find the "Delete Account" option. Please note
                that once your account is deleted, this action cannot be undone,
                and you may lose access to all associated data and connections.
                If you have any questions or need assistance with editing or
                deleting your account, our support team is here to help.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                9. What languages does aKinder™Volunteer support?
              </v-card-title>
              <v-card-text>
                aKinder™Volunteer is accessible in multiple languages including
                English, Spanish, and Portuguese, ensuring a diverse and
                inclusive user experience.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                10. How can I post an event on aKinder™Volunteer?
              </v-card-title>
              <v-card-text>
                Posting an event on aKinder™Volunteer is a straightforward
                process. Make sure you are logged into your agency or volunteer
                account and navigate to the home section. Here, you'll find the
                “Start an Event” button. Click on it, and a form will appear
                prompting you to fill out the required details for your event,
                including text, images, or videos. Once you've filled in all the
                necessary information, click “Post” to publish your event to the
                platform.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                11. What are the social media options on an event post?
              </v-card-title>
              <v-card-text>
                When posting an event, you have the option to simultaneously
                share the event on various social media platforms including
                Facebook, LinkedIn, Instagram, Twitter, and TikTok. This allows
                for wider visibility and promotes your event across different
                networks.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card style="height: 100%;">
              <v-card-title>
                12. How do I enable social media sharing for my event posts?
              </v-card-title>
              <v-card-text>
                To enable social media sharing, go to Settings -> Account, and
                there you will find the option to login and connect your social
                media accounts. Once your accounts are connected, you can easily
                toggle the social media options on and off when creating or
                editing an event post.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <div
      style="background-color: #052E45; padding-bottom: 100px; padding-top: 100px; width: 100%;"
    >
      <v-row class="w-100">
        <v-col cols="12" md="7">
          <div class="d-flex flex-row align-center justify-center px-15">
            <div class="d-flex flex-column">
              <div>
                <v-img width="449" height="78" src="@/assets/logo.svg" />
              </div>
              <span
                class="font-22 app-regular-font"
                style="color: #E7EFF4; margin-top: 32px;"
                >aKinder™Volunteer is a vibrant hub connecting volunteers with
                agencies, streamlining the discovery of and participation in
                meaningful community projects.</span
              >
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <div
            class="d-flex flex-column align-center justify-center h-100 w-100"
          >
            <div class="d-flex flex-column">
              <span class="font-22 app-regular-font" style="color: #E7EFF4"
                >Contact Us</span
              >
              <span
                class="font-22 app-regular-font mt-8"
                style="color: #E7EFF4"
              >
                (781) 964-0000
              </span>
              <span
                class="font-22 app-regular-font mt-4"
                style="color: #E7EFF4"
              >
                dblais@danielstable.org
              </span>
              <span
                class="font-22 app-regular-font mt-4"
                style="color: #E7EFF4"
              >
                23 Howard Street, Braintree, MA 02184
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tutorialVideo:
        "https://akindrcommunity.s3.us-east-2.amazonaws.com/video/tutorial.m4v"
    };
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
    goUserRegister() {
      this.$router.push({ name: "user-register" });
    },
    goAgencyRegister() {
      this.$router.push({ name: "agency-register" });
    }
  }
};
</script>
<style scoped>
.bg-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../assets/bg_about_experience2.png);
  background-size: cover;
  background-position: 50% 50%;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.privacy_title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
.subtitle {
  text-align: center;
  font-size: 18px;
}
.content {
  /* margin-left: 80px; */
  /* margin-right: 80px; */
  max-width: 756px;
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: column;
}
.item-title {
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
}
.item-subtitle {
  font-size: 18px;
  text-decoration: underline;
}
</style>
